<template>
    <app-wrapper>
        <template v-slot:child>
            <div>
                <div class="pa4-l pa3">
                    <div>
                        <h2 class="" style="color: #132c8c; font-size: 18px">Settings</h2>
                        <p class="w-60-l lh-copy black">
                            Control and customize how your Simplebks account works for you. These settings give you full control
                            over your Simplebks experience, from personal preferences to business details and beyond.
                        </p>
                    </div>
                    <div class="mt4 black" style="font-weight: 500">
                        <span class="bg-washed-yellow ph2">Tip: Click on card to perform specify actions </span>
                    </div>
                    <div class="mt4">
                        <div class="flex flex-wrap" style="gap: 15px">
                            <router-link
                                :to="{ name: 'Settings' }"
                                class="pa3 menu-card"
                                style="border: 2px solid #f5f5f7; border-radius: 20px"
                            >
                                <img src="@/assets/images/personal-profile.svg" style="width: 60px; height: 60px" alt="" />
                                <h3>Personal Profile</h3>
                                <p class="lh-copy">View and manage your individual information and preferences.</p>
                            </router-link>
                            <template v-if="role === 'admin' || role === 'owner'">
                                <router-link
                                    :to="{ name: 'BusinessProfile' }"
                                    class="pa3 menu-card"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/business-profile.svg" style="width: 60px; height: 60px" alt="" />
                                    <h3>Business Profile</h3>
                                    <p class="lh-copy">View and edit details about your company or organization.</p>
                                </router-link>
                                <router-link
                                    :to="{ name: 'AccountSettings' }"
                                    class="pa3 menu-card"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/account-setting.svg" style="width: 60px; height: 60px" alt="" />
                                    <h3>Account Settings</h3>
                                    <p class="lh-copy">Customize how your account operates and your security settings.</p>
                                </router-link>
                                <router-link
                                    v-if="!orgData.isSubBusiness && myPlan?.businessPlan !== 'Micro Business Plan'"
                                    :to="{ name: 'BillingNew' }"
                                    class="pa3 menu-card"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/billing-new.svg" style="width: 60px; height: 60px" alt="" />
                                    <h3>Billing</h3>
                                    <p class="lh-copy">Manage your payment information and invoices.</p>
                                </router-link>
                                <router-link
                                    v-if="!orgData.isSubBusiness"
                                    :to="{ name: 'Plan' }"
                                    class="pa3 menu-card"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/Plan.svg" style="width: 60px; height: 60px" alt="" />
                                    <h3>Plans</h3>
                                    <p class="lh-copy">Explore or change your subscription level and features.</p>
                                </router-link>
                                <router-link
                                    v-if="!orgData.isSubBusiness"
                                    :to="{ name: 'Teams' }"
                                    class="pa3 menu-card"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/Team.svg" style="width: 60px; height: 60px" alt="" />
                                    <h3>Teams</h3>
                                    <p class="lh-copy">Organize and manage your team members and their access.</p>
                                </router-link>
                                <router-link
                                    :to="{ name: 'EntitiesArchive' }"
                                    class="pa3 menu-card"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/ArchiveNew.svg" style="width: 60px; height: 60px" alt="" />
                                    <h3>Archives</h3>
                                    <p class="lh-copy">Access past records or older data when needed.</p>
                                </router-link>
                                <router-link
                                    :to="{ name: 'Integrations' }"
                                    class="pa3 menu-card"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/rev-expense.svg" style="width: 60px; height: 60px" alt="" />
                                    <h3>Integrations</h3>
                                    <p class="lh-copy">
                                        Connect Simplebks with other tools or software to enhance its capabilities
                                    </p>
                                </router-link>
                                <router-link
                                    v-if="
                                        !orgData.isSubBusiness &&
                                        myPlan &&
                                        myPlan?.businessPlan !== 'Micro Business Plan' &&
                                        businesses?.length < myPlan?.businesses
                                    "
                                    :to="{ name: 'AddBusiness' }"
                                    class="pa3 menu-card"
                                    style="border: 2px solid #f5f5f7; border-radius: 20px"
                                >
                                    <img src="@/assets/images/rev-expense.svg" style="width: 60px; height: 60px" alt="" />
                                    <h3>Add Business</h3>
                                    <p class="lh-copy">
                                        Connect Simplebks with other tools or software to enhance its capabilities
                                    </p>
                                </router-link>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>
<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import AppWrapper from '../../layout/AppWrapper.vue'
export default {
    name: 'SettingNew',
    components: { AppWrapper },
    setup() {
        const store = useStore()
        const myPlan = computed(() => store.state?.Settings?.paymentPlan)
        const businesses = computed(() => store?.state?.Settings?.businesses)
        const role = computed(() => store?.state?.Auth?.role)
        const orgData = computed(() => store?.state?.Auth?.userData)

        onMounted(() => {
            store.dispatch('Settings/getPlan')
            store.dispatch('Settings/getBusinesses')
        })
        return {
            myPlan,
            businesses,
            role,
            orgData,
        }
    },
}
</script>
